<template lang="pug">
brand-loading-transition
</template>

<script>
  import BrandLoadingTransition from '@/components/LoadingTransition.vue';

  export default {
    components: {
      BrandLoadingTransition,
    },
    mounted() {
      this.$router.go(1);
    },
  };
</script>
